<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Sluiten"
    (click)="activeModal.dismiss()"
  ></button>
</div>
<div class="modal-body">
  <p markdown>
    {{ body }}
  </p>
</div>
